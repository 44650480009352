import React from 'react'
import { Layout, Row, Col } from 'antd'
const { Footer } = Layout

// @ts-ignore
import styles from './footer.module.scss';
import { Link } from 'gatsby';
import Signup from '../Signup';

export default () => (
    <div style={{ backgroundColor: "#3E033F", minWidth: 296 }}  >
        <div className={styles.container}>
            <Row className={styles.footer}>
                <Col xs={24} sm={24} lg={8} style={{ padding: 24, textAlign: 'center' }}>
                    <div className={styles.subheader}>Nyhetsbrev</div>
                    <div className={styles.intro}>
                        Vi vil lansere nye tjenester fortløpende. Melder du deg på nyhetsbrevet fra Oss, kan du følge med på hvordan appen kan gjøre hverdagen din&nbsp;enklere.
                    </div>
                    <div className={styles.signupcontainer}>
                        <Signup />
                    </div>
                </Col>
                <Col xs={24} sm={24} lg={8} style={{ padding: 24, textAlign: 'center' }}>
                    <div className={styles.subheader}>Følg Oss</div>
                    <Row justify="center" style={{ paddingTop: 24 }}>
                        <Col xs={24} sm={24} lg={8} style={{ paddingBottom: 24, textAlign: 'center' }}>
                            <a href="https://no.linkedin.com/company/oss-norge-as" target="_blank" rel="noopener"><div className={styles.linkedin} />
                                LinkedIn</a>
                        </Col>
                        <Col xs={24} sm={24} lg={8} style={{ paddingBottom: 24, textAlign: 'center' }}>
                            <a href="https://www.instagram.com/ossnorge/" target="_blank" rel="noopener"><div className={styles.instagram} />
                                Instagram</a>
                        </Col>
                        <Col xs={24} sm={24} lg={8} style={{ paddingBottom: 24, textAlign: 'center' }}>
                            <a href="https://www.facebook.com/OssNorge" target="_blank" rel="noopener"><div className={styles.facebook} />
                                Facebook</a>
                        </Col>
                    </Row>
                    <Row>
                        <br/>
                        Oss Norge AS, Org.nr 919 439 734
                           Grønland 67, 3045 Drammen
                    </Row>
                </Col>
                <Col xs={24} sm={24} lg={8} style={{ padding: 24, textAlign: 'center' }}>
                    <div className={styles.subheader}>Snarveier</div>
                    <ul>

                        <li>
                            <a className="fade-in" href="https://blogg.oss.no" target="_blank" rel="noopener">Blogg</a>
                        </li>
                        <li>
                            <Link activeClassName={styles.active} className={styles.fadeIn} to="/oss">Om Oss</Link>
                        </li>
                        <li>
                            <Link activeClassName={styles.active} className={styles.fadeIn} to="/jobb">Jobb</Link>
                        </li>

                        <li>
                            <Link activeClassName={styles.active} className={styles.fadeIn} to="/tjenester">Tjenester</Link>
                        </li>
                        <li>
                            <Link activeClassName={styles.active} className={styles.fadeIn} to="/bestill">Bestill</Link>
                        </li>
                        <li>
                            <Link activeClassName={styles.active} className={styles.fadeIn} to="/hjelp">Hjelp</Link>
                        </li>
                        <li>
                            <Link activeClassName={styles.active} className={styles.fadeIn} to="/personvern">Personvern</Link>
                        </li>
                        <li>
                            <Link activeClassName={styles.active} className={styles.fadeIn} to="/brukervilkar">Brukervilkår</Link>
                        </li>
                        <li>
                            <Link activeClassName={styles.active} className={styles.fadeIn} to="/kjopsvilkar">Kjøpsvilkår</Link>
                        </li>
                    </ul>
                </Col>
            </Row>
        </div>
        <div style={{ maxHeight: 300, width: "100%", height: 300, marginTop: -200, minWidth: 296 }}>
            <div className={styles.visualleft} >
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1756.56 286.71" preserveAspectRatio="xMinYMax meet">
                    <path style={{ fill: "#FF495F" }} d="M-211.81,407.93c99.19-785.43,879.47-135.11,1290.6-283.09s394.24-20.07,614.49,95.51S1356.15,573.46,1196.07,537-311,1193.36-211.81,407.93Z" />
                </svg>
            </div>
            <div className={styles.visualright}>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 608.1 440.964" preserveAspectRatio="xMaxYMax meet">
                    <path style={{ fill: "#00FF4A" }} d="M608.1,441V52.6c-125-61.2-261.4-74.8-209-9.4,86.9,108.6-33,185.7-159.9,244.1C108.6,347.2,0,342.7,0,421.2A141.984,141.984,0,0,0,1.4,441Z" transform="translate(0 -0.036)" />
                </svg>
            </div>
        </div>
    </div>
)