
import React from 'react'
import { Layout, Row, Col, Menu, Icon } from 'antd'
import { FaBars } from 'react-icons/fa';
import { Link } from "gatsby"
import SubMenu from 'antd/lib/menu/SubMenu';

// @ts-ignore
import styles from './siteheader.module.scss';

// @ts-ignore
import animatedLogo from '../../assets/animated-logo.mp4';

const { Header } = Layout

/*interface SiteLinkProps {
    animation: string;
    href: string;
    text: string;
}

const SiteLink: React.SFC<SiteLinkProps> = (props) => {
    return (
        <a className={props.animation} href={props.href}>{props.text}</a>
    );
}*/

interface SiteHeaderState {
    menuIsOpen: boolean;
}


export default class SiteHeader extends React.Component<any, SiteHeaderState> {
    constructor(props) {
        super(props);
        this.state = {
            menuIsOpen: false,
        };

        this.onChange = this.onChange.bind(this);
    }

    onChange(e) {
        this.setState({
            menuIsOpen: e.target.checked
        })
    }

    render() {
        return (
            <header>
                <div style={{ position: "relative" }}><div className={styles.navmargin} /></div>
                <div className={styles.nav}>
                    <div className={styles.container}>
                        <Row justify="end">
                            <Col xs={18} sm={6}>
                                <Link to="/"><video width="60" height="60" style={{ display: 'block', float: "left" }} disableremoteplayback={'disableRemotePlayback'} playsInline autoPlay muted loop>
                                    <source src={animatedLogo} type="video/mp4" />
                                    <div className={styles.logo}></div>
                                </video>
                                    <div className={`${styles.wordmark}`}></div>
                                </Link>
                            </Col>
                            <Col xs={6} sm={18} style={{ textAlign: 'right' }}>
                                <nav>
                                    <div className={styles.menu}>
                                        <div className={styles.menuLarge}>
                                            <ul>
                                            <li style={{ paddingLeft: 20 }}>
                                                    <Link activeClassName={styles.active} to="/oss">om Oss</Link>
                                                </li>
                                                <li style={{ paddingLeft: 20 }}>
                                                    <a href="https://blogg.oss.no" target="_blank" rel="noopener">blogg</a>
                                                </li>
                                                <li style={{ paddingLeft: 20 }}>
                                                    <Link activeClassName={styles.active} to="/hjelp">hjelp</Link>
                                                </li>
                                                <li style={{ paddingLeft: 20 }}>
                                                    <Link activeClassName={styles.active} to="/nerdemodus">#nerdemodus</Link>
                                                </li>
                                                <li style={{ paddingLeft: 20 }}>
                                                    <Link activeClassName={styles.active} to="/bestill">bestill</Link>
                                                </li>
                                               
                                                <li style={{ paddingLeft: 20 }}>
                                                    <Link activeClassName={styles.active} to="/tjenester">tjenester</Link>
                                                </li>

                                            </ul>
                                        </div>
                                        <div className={styles.menuSmall}>
                                            <div className={styles.dropdown}>
                                                <input type="checkbox" id="checkbox-toggle" onChange={this.onChange} />
                                                <label className={styles.slideRight} htmlFor="checkbox-toggle">
                                                    <FaBars />
                                                </label>
                                                <div className={styles.dotcontainer}>
                                                    <div id="dot" className={styles.dot}></div>
                                                </div>
                                                <div className={styles.menucontainer}>
                                                    <ul>
                                                        <li className={styles.fadeIn}>
                                                            <Link activeClassName={styles.active} className={styles.fadeIn} to="/tjenester">tjenester</Link>
                                                        </li>
                                                    
                                                        <li>
                                                            <Link activeClassName={styles.active} className={styles.fadeIn} to="/bestill">bestill</Link>
                                                        </li>
                                                       
                                                        <li>
                                                            <Link activeClassName={styles.active} className={styles.fadeIn} to="/nerdemodus">#nerdemodus</Link>
                                                        </li>
                                                        <li style={{ paddingLeft: 20 }}>
                                                            <Link activeClassName={styles.active} to="/hjelp">hjelp</Link>
                                                        </li>
                                                        <li>
                                                            <a className={styles.fadeIn} href="https://blogg.oss.no" target="_blank" rel="noopener">blogg</a>
                                                            <li>
                                                            <Link activeClassName={styles.active} className={styles.fadeIn} to="/oss">om Oss</Link>
                                                        </li>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </nav>
                            </Col>
                        </Row>
                    </div>
                </div>
            </header>
        );
    }
}